.news {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 2rem;
  }

  .headline {
    font-size: 3.5rem;
    margin-block: 3rem;
    text-align: center;
}

.navList {
    list-style: none;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    gap: var(--spacing-small);
    padding: 0;
    margin-bottom: 2rem;
  }
  
  .navLink {
    text-decoration: none;
    color: var(--text-color);
    font-weight: 500;
    font-size: var(--font-size-small);
    transition: all 200ms;
  }
  
  .navLink:hover {
    color: #F59E0B;
  }
  
  .active {
    font-weight: 900;
    color: var(--primary-color);
  }
  
  @media (min-width: 600px) {
    .news {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 2rem;
    }
  }
  