:root {
    --primary-color: #F59E0B;
    --text-color: #FFFFFF;
    --bg-color: #1E1E1E;
    --overlay-color: rgba(59, 59, 59, 0.4);
    --overlay-color-on-hover: rgba(59, 59, 59, 0.6);
    --font-size-small: clamp(1rem, 1.5vw, 3rem);
    --font-size-large: clamp(1rem, 2vw, 3rem);
    --spacing-small: 2vw;
    --spacing-large: 6rem;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    background-color: var(--bg-color);
    color: var(--text-color);
    overflow-y: auto;
}

.wrapper {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: clamp(100vh, fit-content, 100.1vh);
    background: url('../images/background_home.jpg') no-repeat right center;
    background-size: cover;
}

nav {
    width: 100%;
    min-height: 30vh;
    position: sticky;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-transform: uppercase;
    /* border-bottom: var(--primary-color) solid .2rem; */
}

nav ul {
    list-style-type: none;
    display: flex;
    gap: var(--spacing-small);
}

nav ul li a {
    text-decoration: none;
    color: var(--text-color);
    font-weight: 500;
    font-size: var(--font-size-small);
    transition: all 200ms;
}

.active {
    font-weight: 900;
    color: var(--primary-color);
}

nav ul li a:hover {
    color: var(--primary-color);
}

svg {
    width: clamp(10rem, 70%, 700px);
    margin-bottom: var(--spacing-large);
}

path {
    stroke-dasharray: 1;
    stroke-dashoffset: 1;
    animation: dash 1.5s linear forwards;
}

@keyframes dash {
    from {
        stroke-dashoffset: 1;
    }
    to {
        stroke-dashoffset: 0;
    }
}

main {
    width: 100%;
    min-height: 70vh;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    flex-direction: column;
    margin-top: var(--spacing-large);
    padding-inline: 10vw;
    color: var(--text-color);
}

article {
    width: 100%;

}

.paragraph {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: var(--font-size-small);
}

.paragraph :nth-child(2) {
    margin-block: 2vh;
}

.paragraph h1 {
    font-size: var(--font-size-large);
}

.paragraph img {
    height: 5rem;
    margin-inline: 3vw;
}

.moreBtn {
    margin-top: var(--spacing-small);
    font-size: var(--font-size-small);
    text-decoration: none;
    color: var(--text-color);
    padding: 0.5rem 1rem;
    border-radius: .5rem;
    background: var(--overlay-color-on-hover);
    cursor: pointer;
    border: 2px solid var(--primary-color);
}

.moreBtn:hover {
    color: var(--primary-color);
    transition: all 300ms;
}

.contactBtnGroup {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-block: 2rem;
    margin-top: var(--spacing-large);
    margin-bottom: var(--spacing-small);
    background: var(--overlay-color);
    backdrop-filter: blur(5px);
    border-radius: 1rem;
}

.contactBtnGroup a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: var(--spacing-small);
    text-decoration: none;
    color: var(--text-color);
    font-size: var(--font-size-small);
}

.contactBtnGroup a:hover img {
    transform: scale(1.1);
}

.contactBtnGroup a img {
    width: clamp(1rem, 2vw, 5rem);
    height: fit-content;
    margin-right: 0.5rem;
    transition: all 200ms;
}

.imprBtn {
    color: var(--text-color);
    margin-block: var(--spacing-small);
    text-align: center;
}

@media (max-width: 800px) {
    .wrapper {
        background-position: center center;
        overflow: auto;
    }
    nav {
        height: 35vh;
    }
    nav ul {
        flex-wrap: wrap;
    }
    article {
        height: fit-content;
    }
    .contactBtnGroup {
        padding-block: 1rem;
    }
    .contactBtnGroup a img {
        width: clamp(1rem, 10vw, 5rem);
    }
    .contactBtnGroup a > *:not(img) {
        display: none;
    }
}
