.container {
    display: flex;
    height: 100vh;
    overflow: hidden;
  }
  
  .imageSection {
    flex: 1;
    background: url('../images/background_contact.jpg') no-repeat left center;
    background-size: cover;
    height: 100%;
    min-width: 50%;
  }
  
  .infoSection {
    flex: 1;
    background-color: #1E1E1E;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    height: 100%;
    min-width: 50%;
    overflow-y: auto;
  }
  
  .infoContent {
    text-align: center;
    max-width: 600px;
    margin: 0 auto;
    background-color: #1E1E1E;
    color: #FFFFFF;
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .infoContent h2 {
    font-size: clamp(1.5rem, 2.5vw, 2.5rem);
    margin-bottom: 3rem;
    color: #F59E0B;
  }
  
  .infoContent p {
    font-size: clamp(1rem, 1.5vw, 1.2rem);
    line-height: 1.6;
    margin-bottom: 3rem;
    text-align: left;
  }

  .infoContent br {
    margin-bottom: 1rem;
    text-align: left;
  }
  
  .infoContent strong {
    font-weight: bold;
    font-size: clamp(1.2rem, 2vw, 1.5rem);
  }

  .infoContent a {
    color: #F59E0B;
    text-decoration: none;
  }
  
  .infoContent a:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 800px) {
    .container {
      flex-direction: column;
    }
  
    .imageSection,
    .infoSection {
      min-width: 100%;
      height: 50vh;
    }
  
    .infoContent {
      width: 100%;
      padding: 1rem;
      margin: 0;
    }
  }
  