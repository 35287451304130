.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    max-width: 20rem;
    margin: 1rem;
    padding-bottom: 1rem;
    background-color: #272727;
    border-radius: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.342);
    transition: all 300ms ease;
  }
  
  .card:hover {
    transform: scale(1.02);
    box-shadow: 0 4px 8px #0056b3;
  }
  
  .cardImage {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    margin-bottom: 1rem;
  }
  
  .cardText {
    font-size: 1.2rem;
    text-align: center;
    padding-inline: 1rem;
    margin-bottom: 1rem;
  }
  
  .cardButton {
    font-size: 1rem;
    text-decoration: none;
    color: white;
    background-color: #007bff;
    padding: 0.75rem 1.5rem;
    border-radius: 0.75rem;
    transition: background-color 300ms ease;
  }
  
  .cardButton:hover {
    background-color: #0056b3;
  }
  