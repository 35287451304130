.article {
    width: 90%;
    max-width: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem 0;
  }
  
  .thumbnail {
    width: 90%;
    height: auto;
    border-radius: 1rem;
    margin-bottom: 1rem;
  }
  
  .textContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .articleText {
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .moreButton {
    font-size: 1rem;
    text-decoration: none;
    color: white;
    background-color: #007bff;
    padding: 0.75rem 1.5rem;
    border-radius: 0.75rem;
    transition: background-color 300ms ease;
  }
  
  .moreButton:hover {
    background-color: #0056b3;
  }
  