body {
    line-height: 1.6;
    overflow-y: auto;
}

.container {
    background-color: #121212;
    padding: 2rem;
    margin: 5vh auto;
    max-width: 80vw;
    border-radius: 0.5rem;
}

.header {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 2rem;
    color: #ffffff;
}

address {
    font-style: normal;
    margin-bottom: 2rem;
}

.container p {
    margin-bottom: 2rem;
    line-height: 1.5;
}

.container a {
    color: #bb86fc;
    text-decoration: none;
    transition: color 0.3s ease;
}

.container a:hover {
    color: #9c4dcc;
}

.container Link {
    font-weight: bold;
    color: #bb86fc;
    text-decoration: none;
}

.container Link:hover {
    color: #9c4dcc;
    text-decoration: underline;
}
  